import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"d-flex text-center",staticStyle:{"height":"100%","padding-top":"10vw"}},[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{attrs:{"xs":"12"}},[_c('span',{staticClass:"display-4 font-weight-bold pr-1 grey--text"},[_vm._v(" 4 "),_c(VIcon,{attrs:{"color":"grey","size":"100"}},[_vm._v(" mdi-emoticon-dead-outline ")]),_vm._v(" 4 ")],1),_c('p',{staticClass:"display-1 pt-3"},[_vm._v(" Ops! Página não encontrada ")]),_c(VBtn,{attrs:{"color":_vm.$vuetify.theme.dark ? '' : 'primary',"dark":"","large":""},on:{"click":function($event){return _vm.voltarInicio()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-reply ")]),_vm._v(" Retornar ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }